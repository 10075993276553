import * as React from "react";
import { HeadProps, Link } from "gatsby";
import Seo from "../features/seo/Seo";
import FormPageWrapper from "../components/FormPageWrapper";
import Layout from "../components/Layout";
import { StaticImage } from "gatsby-plugin-image";

// markup
const NotFoundPage = () => {
  return (
    <>
      <Layout>
        <FormPageWrapper>
          <div className="grid gap-4 sm:grid-cols-2 justify-items-center items-center">
            <div>
              <p className="text-amber-700 text-4xl mb-4 text-center">404</p>
              <p className="text-2xl sm:text-4xl mb-6 text-neutral-700 text-center">
                Oh, no! Page not found.
              </p>
              <p className="py-4 text-center">
                <Link
                  className="sm:w-auto bg-blue-700 text-white rounded-lg transition-colors
                  focus:bg-blue-600 hover:bg-blue-600 active:bg-blue-800 text-xl py-4 px-8"
                  to="/"
                >
                  Go Home
                </Link>
              </p>
            </div>
            <div>
              <StaticImage
                src="../images/404-shaun.png"
                alt="Shaun looking confused"
              />
            </div>
          </div>
        </FormPageWrapper>
      </Layout>
    </>
  );
};

export default NotFoundPage;

export const Head = (props: HeadProps) => {
  return (
    <Seo
      title="Not found | Giancoli Answers"
      pathname={props.location.pathname}
    />
  );
};
